var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        [
          _c(
            "el-row",
            { attrs: { gutter: 24 } },
            [
              _c("el-col", { staticClass: "formTitle", attrs: { md: 8 } }, [
                _vm._v("基础信息"),
              ]),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 24 } },
            [
              _c(
                "el-col",
                { attrs: { md: 8 } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "flex",
                      attrs: { label: "活动名称", required: "" },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "活动名称",
                          readonly: _vm.disabled,
                        },
                        model: {
                          value: _vm.value.activityName,
                          callback: function ($$v) {
                            _vm.$set(_vm.value, "activityName", $$v)
                          },
                          expression: "value.activityName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { md: 8 } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "flex",
                      attrs: { label: "活动类型", required: "" },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            disabled: !_vm.isAdd,
                            placeholder: "活动类型",
                          },
                          model: {
                            value: _vm.value.activityType,
                            callback: function ($$v) {
                              _vm.$set(_vm.value, "activityType", $$v)
                            },
                            expression: "value.activityType",
                          },
                        },
                        _vm._l(_vm.options, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { md: 8 } },
                [
                  _vm.value.activityType === "display"
                    ? _c(
                        "el-form-item",
                        {
                          staticClass: "flex",
                          attrs: { label: "活动执行频率", required: "" },
                        },
                        [
                          _c(
                            "el-input",
                            {
                              attrs: {
                                placeholder: "活动执行频率",
                                readonly: _vm.disabled,
                              },
                              model: {
                                value: _vm.value.activityFrequency,
                                callback: function ($$v) {
                                  _vm.$set(_vm.value, "activityFrequency", $$v)
                                },
                                expression: "value.activityFrequency",
                              },
                            },
                            [
                              _c("template", { slot: "suffix" }, [
                                _vm._v("天/1次"),
                              ]),
                            ],
                            2
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 24 } },
            [
              _c(
                "el-col",
                { attrs: { md: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "flex",
                      attrs: { label: "活动执行时间", required: "" },
                    },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          readonly: _vm.disabled,
                          "value-format": "yyyy-MM-dd",
                          type: "daterange",
                          "picker-options": _vm.pickerOption,
                          "range-separator": "至",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期",
                        },
                        on: { change: _vm.getDate },
                        model: {
                          value: _vm.activityDate,
                          callback: function ($$v) {
                            _vm.activityDate = $$v
                          },
                          expression: "activityDate",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.value.activityType === "cost"
            ? _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "el-col",
                    { attrs: { md: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { staticClass: "flex", attrs: { label: "预估销售额" } },
                        [
                          _c("el-input-number", {
                            attrs: { disabled: _vm.disabled },
                            model: {
                              value: _vm.value.salesVolume,
                              callback: function ($$v) {
                                _vm.$set(_vm.value, "salesVolume", $$v)
                              },
                              expression: "value.salesVolume",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { md: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "flex",
                          attrs: { label: "费用申请金额" },
                        },
                        [
                          _c("el-input-number", {
                            attrs: { disabled: _vm.disabled },
                            model: {
                              value: _vm.value.applyAmount,
                              callback: function ($$v) {
                                _vm.$set(_vm.value, "applyAmount", $$v)
                              },
                              expression: "value.applyAmount",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-row",
            { attrs: { gutter: 24 } },
            [
              _c(
                "el-col",
                { attrs: { md: 10 } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "flex",
                      attrs: { label: "活动要求", required: "" },
                    },
                    [
                      _c(
                        "el-checkbox-group",
                        {
                          on: { change: _vm.getCheckBox },
                          model: {
                            value: _vm.checkList,
                            callback: function ($$v) {
                              _vm.checkList = $$v
                            },
                            expression: "checkList",
                          },
                        },
                        _vm._l(_vm.requireList, function (item, index) {
                          return _c(
                            "el-checkbox",
                            {
                              key: index,
                              attrs: {
                                label: item.dictCode,
                                disabled: _vm.disabled,
                              },
                            },
                            [_vm._v(" " + _vm._s(item.dictValue) + " ")]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 24 } },
            [
              _c(
                "el-col",
                { attrs: { md: 16 } },
                [
                  _c(
                    "el-form-item",
                    { staticClass: "flex", attrs: { label: "活动描述" } },
                    [
                      _c("el-input", {
                        attrs: { type: "textarea", readonly: _vm.disabled },
                        model: {
                          value: _vm.value.activityDesc,
                          callback: function ($$v) {
                            _vm.$set(_vm.value, "activityDesc", $$v)
                          },
                          expression: "value.activityDesc",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.value.activityType === "display"
            ? _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "el-col",
                    { staticClass: "mt20", attrs: { md: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { staticClass: "flex", attrs: { label: "示例图片" } },
                        [
                          _c("FormUpload", {
                            attrs: {
                              limit: 3,
                              disabled: _vm.disabled,
                              value: _vm.value.visitPictureList || [],
                              fileMsg: "只能上传jpg/png/jpeg文件，且不超过2MB",
                              accept: ".png,.jpg,.jpeg",
                            },
                            on: { onGetFileList: _vm.onGetFileList },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.value.activityType === "display"
            ? _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "el-col",
                    { attrs: { md: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { staticClass: "flex", attrs: { label: "陈列要求" } },
                        [
                          _c(
                            "div",
                            [
                              _c(
                                "div",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "primary", size: "small" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.add("product")
                                        },
                                      },
                                    },
                                    [_vm._v("添加产品")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: "danger",
                                        size: "small",
                                        icon: "el-icon-delete",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.clearData("productList")
                                        },
                                      },
                                    },
                                    [_vm._v("清空")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "vxe-table",
                                {
                                  ref: "xTable",
                                  staticClass: "mt20",
                                  attrs: {
                                    "edit-config": {
                                      trigger: "click",
                                      mode: "row",
                                      activeMethod: _vm.activeCellMethod,
                                    },
                                    data: _vm.productList,
                                  },
                                },
                                [
                                  _c("vxe-table-column", {
                                    attrs: {
                                      field: "seriesName",
                                      title: "产品层级",
                                    },
                                  }),
                                  _c("vxe-table-column", {
                                    attrs: {
                                      field: "productCode",
                                      title: "产品编码",
                                    },
                                  }),
                                  _c("vxe-table-column", {
                                    attrs: {
                                      field: "productName",
                                      title: "产品名称",
                                    },
                                  }),
                                  _c("vxe-table-column", {
                                    attrs: {
                                      field: "displayNum",
                                      title: "最低陈列面数",
                                      "edit-render": {
                                        name: "$input",
                                        props: { type: "number" },
                                      },
                                    },
                                  }),
                                  !_vm.disabled
                                    ? _c("vxe-table-column", {
                                        attrs: { title: "操作" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (ref) {
                                                var row = ref.row
                                                return [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "cursor",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.delTable(
                                                            row,
                                                            "productList"
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("删除")]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          71176954
                                        ),
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-row",
            { attrs: { gutter: 24 } },
            [
              _c(
                "el-col",
                { attrs: { md: 24 } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "flex",
                      attrs: { label: "活动范围", required: "" },
                    },
                    [
                      _c(
                        "el-tabs",
                        {
                          on: { "tab-click": _vm.handleClick },
                          model: {
                            value: _vm.activeName,
                            callback: function ($$v) {
                              _vm.activeName = $$v
                            },
                            expression: "activeName",
                          },
                        },
                        [
                          _c(
                            "el-tab-pane",
                            { attrs: { label: "组织", name: "first" } },
                            [
                              !_vm.disabled
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: "primary",
                                        size: "small",
                                        icon: "el-icon-plus",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.add("org")
                                        },
                                      },
                                    },
                                    [_vm._v("添加组织")]
                                  )
                                : _vm._e(),
                              !_vm.disabled
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: "danger",
                                        size: "small",
                                        icon: "el-icon-delete",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.clearData("orgList")
                                        },
                                      },
                                    },
                                    [_vm._v("清空")]
                                  )
                                : _vm._e(),
                              _c(
                                "vxe-table",
                                {
                                  staticClass: "mt20",
                                  attrs: {
                                    "edit-config": {
                                      trigger: "click",
                                      mode: "row",
                                    },
                                    data: _vm.orgList,
                                  },
                                },
                                [
                                  _c("vxe-table-column", {
                                    attrs: { field: "code", title: "组织编码" },
                                  }),
                                  _c("vxe-table-column", {
                                    attrs: { field: "name", title: "组织名称" },
                                  }),
                                  !_vm.disabled
                                    ? _c("vxe-table-column", {
                                        attrs: { title: "操作" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (ref) {
                                                var row = ref.row
                                                return [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "cursor",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.delTable(
                                                            row,
                                                            "orgList"
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("删除")]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          195971115
                                        ),
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-tab-pane",
                            { attrs: { label: "渠道", name: "second" } },
                            [
                              !_vm.disabled
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: "primary",
                                        size: "small",
                                        icon: "el-icon-plus",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.add("channel")
                                        },
                                      },
                                    },
                                    [_vm._v("添加渠道")]
                                  )
                                : _vm._e(),
                              !_vm.disabled
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: "danger",
                                        size: "small",
                                        icon: "el-icon-delete",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.clearData("channelList")
                                        },
                                      },
                                    },
                                    [_vm._v("清空")]
                                  )
                                : _vm._e(),
                              _c(
                                "vxe-table",
                                {
                                  staticClass: "mt20",
                                  attrs: {
                                    "edit-config": {
                                      trigger: "click",
                                      mode: "row",
                                    },
                                    data: _vm.channelList,
                                  },
                                },
                                [
                                  _c("vxe-table-column", {
                                    attrs: { field: "code", title: "渠道编码" },
                                  }),
                                  _c("vxe-table-column", {
                                    attrs: { field: "name", title: "渠道名称" },
                                  }),
                                  !_vm.disabled
                                    ? _c("vxe-table-column", {
                                        attrs: { title: "操作" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (ref) {
                                                var row = ref.row
                                                return [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "cursor",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.delTable(
                                                            row,
                                                            "channelList"
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("删除")]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          1070360626
                                        ),
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-tab-pane",
                            { attrs: { label: "客户", name: "third" } },
                            [
                              !_vm.disabled
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: "primary",
                                        size: "small",
                                        icon: "el-icon-plus",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.add("customer")
                                        },
                                      },
                                    },
                                    [_vm._v("添加客户")]
                                  )
                                : _vm._e(),
                              !_vm.disabled
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: "danger",
                                        size: "small",
                                        icon: "el-icon-delete",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.clearData("customerList")
                                        },
                                      },
                                    },
                                    [_vm._v("清空")]
                                  )
                                : _vm._e(),
                              _c(
                                "vxe-table",
                                {
                                  staticClass: "mt20",
                                  attrs: {
                                    "edit-config": {
                                      trigger: "click",
                                      mode: "row",
                                    },
                                    data: _vm.customerList,
                                  },
                                },
                                [
                                  _c("vxe-table-column", {
                                    attrs: { field: "name", title: "客户编码" },
                                  }),
                                  _c("vxe-table-column", {
                                    attrs: { field: "code", title: "客户名称" },
                                  }),
                                  !_vm.disabled
                                    ? _c("vxe-table-column", {
                                        attrs: { title: "操作" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (ref) {
                                                var row = ref.row
                                                return [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "cursor",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.delTable(
                                                            row,
                                                            "customerList"
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("删除")]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          3989799061
                                        ),
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.isOrg
        ? _c("OrgDialog", {
            attrs: { orgList: _vm.orgList },
            on: { submitOrg: _vm.submitOrg, closeOrg: _vm.closeOrg },
          })
        : _vm._e(),
      _vm.isChannelOrCustomer
        ? _c("AddDialog", {
            attrs: {
              isChannel: _vm.isChannel,
              isCustomer: _vm.isCustomer,
              isProduct: _vm.isProduct,
            },
            on: { closeAdd: _vm.closeAdd, submitAdd: _vm.submitAdd },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }