<template>
  <div>
    <el-form>
      <el-row :gutter="24">
        <el-col :md="8" class="formTitle">基础信息</el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :md="8">
          <el-form-item label="活动名称" required class="flex">
            <el-input placeholder="活动名称" v-model="value.activityName" :readonly="disabled"></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="活动类型" required class="flex">
            <el-select v-model="value.activityType" :disabled="!isAdd" placeholder="活动类型">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="活动执行频率" required class="flex" v-if="value.activityType === 'display'">
            <el-input placeholder="活动执行频率" v-model="value.activityFrequency" :readonly="disabled">
              <template slot="suffix">天/1次</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :md="12">
          <el-form-item label="活动执行时间" required class="flex">
            <el-date-picker
              :readonly="disabled"
              value-format="yyyy-MM-dd"
              v-model="activityDate"
              type="daterange"
              :picker-options="pickerOption"
              range-separator="至"
              @change="getDate"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            ></el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24" v-if="value.activityType === 'cost'">
        <el-col :md="8">
          <el-form-item label="预估销售额" class="flex">
            <el-input-number v-model="value.salesVolume" :disabled="disabled"></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="费用申请金额" class="flex">
            <el-input-number v-model="value.applyAmount" :disabled="disabled"></el-input-number>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :md="10">
          <el-form-item label="活动要求" required class="flex">
            <el-checkbox-group v-model="checkList" @change="getCheckBox">
              <el-checkbox
                :label="item.dictCode"
                v-for="(item, index) in requireList"
                :key="index"
                :disabled="disabled">
                {{ item.dictValue }}
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :md="16">
          <el-form-item label="活动描述" class="flex">
            <el-input type="textarea" :readonly="disabled" v-model="value.activityDesc"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24" v-if="value.activityType === 'display'">
        <el-col :md="12" class="mt20">
          <el-form-item label="示例图片" class="flex">
            <FormUpload :limit="3" :disabled="disabled" :value="value.visitPictureList || []" @onGetFileList="onGetFileList" :fileMsg="'只能上传jpg/png/jpeg文件，且不超过2MB'" :accept="'.png,.jpg,.jpeg'"></FormUpload>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24" v-if="value.activityType === 'display'">
        <el-col :md="24">
          <el-form-item label="陈列要求" class="flex">
            <div>
              <div>
                <el-button type="primary" size="small" @click="add('product')">添加产品</el-button>
                <el-button type="danger" size="small" @click="clearData('productList')" icon="el-icon-delete">清空</el-button>
              </div>
              <vxe-table ref="xTable" :edit-config="{ trigger:'click', mode: 'row', activeMethod: activeCellMethod}" class="mt20" :data="productList">
                <vxe-table-column field="seriesName" title="产品层级"></vxe-table-column>
                <vxe-table-column field="productCode" title="产品编码"></vxe-table-column>
                <vxe-table-column field="productName" title="产品名称"></vxe-table-column>
                <vxe-table-column field="displayNum" title="最低陈列面数" :edit-render="{name: '$input', props: {type: 'number'}}"></vxe-table-column>
                <vxe-table-column title="操作" v-if="!disabled">
                    <template v-slot="{ row }">
                      <span class="cursor" @click="delTable(row, 'productList')">删除</span>
                    </template>
                </vxe-table-column>
              </vxe-table>
            </div>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :md="24">
          <el-form-item label="活动范围" required class="flex">
            <el-tabs v-model="activeName" @tab-click="handleClick">
              <el-tab-pane label="组织" name="first">
                <el-button type="primary" v-if="!disabled" size="small" icon="el-icon-plus" @click="add('org')">添加组织</el-button>
                <el-button type="danger" v-if="!disabled" size="small" icon="el-icon-delete" @click="clearData('orgList')">清空</el-button>
                <vxe-table :edit-config="{ trigger:'click', mode: 'row'}" class="mt20" :data="orgList">
                  <vxe-table-column field="code" title="组织编码"></vxe-table-column>
                  <vxe-table-column field="name" title="组织名称"></vxe-table-column>
                  <vxe-table-column title="操作" v-if="!disabled">
                      <template v-slot="{ row }">
                        <span class="cursor" @click="delTable(row, 'orgList')">删除</span>
                      </template>
                  </vxe-table-column>
                </vxe-table>
              </el-tab-pane>
              <el-tab-pane label="渠道" name="second">
                <el-button type="primary" size="small" v-if="!disabled" icon="el-icon-plus" @click="add('channel')">添加渠道</el-button>
                <!-- <el-button type="primary" size="small" v-if="!disabled">导入</el-button> -->
                <el-button type="danger" size="small" v-if="!disabled" icon="el-icon-delete" @click="clearData('channelList')">清空</el-button>
                <vxe-table :edit-config="{ trigger:'click', mode: 'row'}" class="mt20" :data="channelList">
                  <vxe-table-column field="code" title="渠道编码"></vxe-table-column>
                  <vxe-table-column field="name" title="渠道名称"></vxe-table-column>
                  <vxe-table-column title="操作" v-if="!disabled">
                      <template v-slot="{ row }">
                        <span class="cursor" @click="delTable(row, 'channelList')">删除</span>
                      </template>
                  </vxe-table-column>
                </vxe-table>
              </el-tab-pane>
              <el-tab-pane label="客户" name="third">
                <el-button type="primary" size="small" v-if="!disabled" icon="el-icon-plus" @click="add('customer')">添加客户</el-button>
                <!-- <el-button type="primary" size="small" v-if="!disabled">导入</el-button> -->
                <el-button type="danger" size="small" v-if="!disabled" icon="el-icon-delete" @click="clearData('customerList')">清空</el-button>
                <vxe-table :edit-config="{ trigger:'click', mode: 'row'}" class="mt20" :data="customerList">
                  <vxe-table-column field="name" title="客户编码"></vxe-table-column>
                  <vxe-table-column field="code" title="客户名称"></vxe-table-column>
                  <vxe-table-column title="操作" v-if="!disabled">
                      <template v-slot="{ row }">
                        <span class="cursor" @click="delTable(row, 'customerList')">删除</span>
                      </template>
                  </vxe-table-column>
                </vxe-table>
              </el-tab-pane>
            </el-tabs>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <!-- 组织 -->
    <OrgDialog v-if="isOrg" @submitOrg="submitOrg" @closeOrg="closeOrg" :orgList="orgList"></OrgDialog>
    <!-- 渠道、客户 -->
    <AddDialog v-if="isChannelOrCustomer" :isChannel="isChannel" :isCustomer="isCustomer" :isProduct="isProduct" @closeAdd="closeAdd" @submitAdd="submitAdd"></AddDialog>
  </div>
</template>
<script>
import * as utils from '../../../../../../../utils/index';
import request from '../../../../../../../utils/request';
import FormUpload from '../../../../../../../components/form/components/form_upload.vue';
import OrgDialog from './org_dialog.vue';
import AddDialog from './add_dialog.vue';

export default {
  props: {
    value: Object,
    disabled: Boolean,
    isAdd: Boolean,
  },
  components: {
    FormUpload,
    OrgDialog,
    AddDialog,
  },
  data() {
    return {
      isOrg: false, // 是否是组织弹窗
      isChannelOrCustomer: false,
      isChannel: false, // 是否是渠道弹窗
      isProduct: false, // 是否是陈列产品弹窗
      isCustomer: false, // 是否是客户弹窗
      activeName: 'first',
      typeList: [],
      options: [], // 活动类型下拉数据
      pickerOption: {
        disabledDate: (time) => time.getTime() < new Date() - 8.64e7,
      },
      activityDate: [],
      checkList: [], // 活动要求list
      orgList: [], // 添加后组织数据
      requireList: [], // 活动要求多选数据
      channelList: [], // 添加后渠道数据
      customerList: [], // 添加后客户数据
      productList: [], // 添加后陈列商品数据
    };
  },
  created() {
    this.getDict();
    this.$emit('input', this.value);
  },
  watch: {
    value: {
      deep: true,
      handler(val) {
        if (!this.isAdd) {
          console.log(val);
          this.activityDate = val.activityDate;
          this.checkList = val.checkList;
          this.productList = val.activityProductList;
          this.channelList = val.channelRangeList;
          this.customerList = val.customerRangeList;
          this.orgList = val.orgRangeList;
          if (val.visitPictureList) {
            val.visitPictureList.map((v) => {
              const item = v;
              item.fileName = item.fileName ? item.fileName : item.picDesc;
              item.url = item.picUrl + item.picPath;
              item.urlPath = item.picPath;
              item.urlPathPrefix = item.picUrl;
              item.objectName = item.picDesc;
              return item;
            });
          }
        }
      },
    },
    productList: {
      deep: true,
      handler() {
        this.value.activityProductList = this.productList;
      },
    },
  },
  methods: {
    activeCellMethod({ columnIndex }) {
      if (columnIndex === 3 && !this.isAdd) {
        return false;
      }
      return true;
    },
    getDict() {
      request.post('/mdm/mdmdictdata/batchDictSelect', ['sfa_activity_type', 'sfa_activity_require']).then((res) => {
        const resData = res.result.sfa_activity_type;
        this.requireList = res.result.sfa_activity_require; // 活动要求
        this.options = resData.map((item) => ({
          label: item.dictValue,
          value: item.dictCode,
        }));
      });
    },
    // 选择活动要求
    getCheckBox(e) {
      this.value.activityRequire = e.join(',');
      this.value.checkList = e;
      this.$emit('input', this.value);
    },
    // 获取日期
    getDate(e) {
      const [activityStartTime, activityEndTime] = e;
      this.value.activityStartTime = activityStartTime;
      this.value.activityEndTime = activityEndTime;
      this.value.activityDate = e;
      this.$emit('input', this.value);
    },
    // tab切换
    handleClick() {

    },
    // 清空
    clearData(list) {
      this[list] = [];
      this.emitChange();
    },
    // 删除
    delTable(row, list) {
      const index = this[list].findIndex((val) => val.code === row.code);
      if (index > -1) {
        this[list].splice(index, 1);
      }
      this.emitChange();
    },
    // 添加组织、渠道、客户
    add(type) {
      if (type === 'org') {
        this.isOrg = true;
      } else if (type === 'channel') {
        this.isChannelOrCustomer = true;
        this.isChannel = true;
        this.isCustomer = false;
        this.isProduct = false;
      } else if (type === 'customer') {
        this.isChannelOrCustomer = true;
        this.isChannel = false;
        this.isCustomer = true;
        this.isProduct = false;
      } else {
        this.isChannelOrCustomer = true;
        this.isChannel = false;
        this.isCustomer = false;
        this.isProduct = true;
      }
    },
    // 获取组织
    submitOrg(e) {
      this.isOrg = false;
      // this.orgList = utils.uniqueArr(this.orgList || [], e || [], 'code');
      this.orgList = e || [];
      this.emitChange();
    },
    // 关闭组织弹窗
    closeOrg() {
      this.isOrg = false;
    },
    // 关闭渠道或客户弹窗或陈列产品
    closeAdd() {
      this.isChannelOrCustomer = false;
    },
    // 获取渠道或客户数据或陈列产品
    submitAdd(e) {
      this.isChannelOrCustomer = false;
      if (this.isChannel) {
        this.channelList = utils.uniqueArr(this.channelList || [], e || [], 'code');
      } else if (this.isCustomer) {
        this.customerList = utils.uniqueArr(this.customerList || [], e || [], 'code');
      } else if (this.isProduct) {
        this.productList = utils.uniqueArr(this.productList || [], e || [], 'code');
      }
      this.emitChange();
    },
    // table数据传到父组件
    emitChange() {
      this.value.activityProductList = this.productList;
      this.value.orgRangeList = this.orgList;
      this.value.channelRangeList = this.channelList;
      this.value.customerRangeList = this.customerList;
      this.value.activityRangeList = this.orgList.concat(this.channelList).concat(this.customerList);
      this.$emit('input', this.value);
    },
    // 获取上传数据
    onGetFileList(e) {
      const arr = [];
      e.map((v) => {
        const item = v;
        arr.push({
          picPath: item.urlPath,
          picUrl: item.urlPathPrefix,
          picDesc: item.objectName,
          ...item,
        });
        return item;
      });

      this.$set(this.value, 'visitPictureList', arr);
      this.$emit('input', this.value);
      this.$forceUpdate();
    },
  },
};
</script>
<style lang="less" scoped>
.flex {
  display: flex;
}
.el-row {
  margin: 0 !important;
}
/deep/ .el-form-item__label {
  width: 125px;
}
/deep/ .el-form-item__content {
  flex: 1;
  display: flex;
}
/deep/ .el-input-number{
  flex: 1;
}
.el-col {
  padding: 0 !important;
}
.formTitle{
  font-size: 16px;
  color: #333;
  margin-bottom: 15px;
}
.search{
  position: relative;
}
.el-icon-search{
  position: absolute;
  right: 10px;
  font-size: 18px;
  z-index: 1;
  top: 7px;
  cursor: pointer;
}
.dialog-footer {
  position: absolute;
  bottom: 0;
  right: 20px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  z-index: 99;
  background-color: white;
}
.mt20{
  margin-top: 20px;
}
.cursor{
  cursor: pointer;
}
</style>
