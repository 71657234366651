var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "Modal",
        {
          attrs: { modalConfig: _vm.modalConfig },
          on: { onClose: _vm.dialog_btnClose },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 24 } },
            [
              _c(
                "el-col",
                { attrs: { md: 8 } },
                [
                  _vm.isCustomer
                    ? _c(
                        "el-select",
                        {
                          staticClass: "mb20",
                          attrs: {
                            filterable: "",
                            clearable: "",
                            remote: "",
                            "reserve-keyword": "",
                            placeholder: "请输入客户编码或名称",
                            "remote-method": function (query) {
                              _vm.remoteMethod(query, "customer")
                            },
                            loading: _vm.loading,
                          },
                          on: {
                            clear: _vm.getClear,
                            change: function ($event) {
                              return _vm.getChange($event, "code")
                            },
                          },
                          model: {
                            value: _vm.value,
                            callback: function ($$v) {
                              _vm.value = $$v
                            },
                            expression: "value",
                          },
                        },
                        _vm._l(_vm.channelOptions, function (item) {
                          return _c("el-option", {
                            key: item.code,
                            attrs: { label: item.name, value: item.code },
                          })
                        }),
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { md: 8 } },
                [
                  _vm.isProduct
                    ? _c(
                        "el-select",
                        {
                          staticClass: "mb20",
                          attrs: {
                            filterable: "",
                            clearable: "",
                            remote: "",
                            "reserve-keyword": "",
                            placeholder: "请输入商品编码",
                            "remote-method": function (query) {
                              _vm.remoteMethod(query, "code")
                            },
                            loading: _vm.loading,
                          },
                          on: {
                            clear: _vm.getClear,
                            change: function ($event) {
                              return _vm.getChange($event, "code")
                            },
                          },
                          model: {
                            value: _vm.productCode,
                            callback: function ($$v) {
                              _vm.productCode = $$v
                            },
                            expression: "productCode",
                          },
                        },
                        _vm._l(_vm.productCodeOptions, function (item) {
                          return _c("el-option", {
                            key: item.productCode,
                            attrs: {
                              label: item.productCode,
                              value: item.productCode,
                            },
                          })
                        }),
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { md: 8 } },
                [
                  _vm.isProduct
                    ? _c(
                        "el-select",
                        {
                          staticClass: "mb20",
                          attrs: {
                            clearable: "",
                            filterable: "",
                            remote: "",
                            "reserve-keyword": "",
                            placeholder: "请输入商品名称",
                            "remote-method": function (query) {
                              _vm.remoteMethod(query, "name")
                            },
                            loading: _vm.loading,
                          },
                          on: {
                            clear: _vm.getClear,
                            change: function ($event) {
                              return _vm.getChange($event, "name")
                            },
                          },
                          model: {
                            value: _vm.productName,
                            callback: function ($$v) {
                              _vm.productName = $$v
                            },
                            expression: "productName",
                          },
                        },
                        _vm._l(_vm.productNameOptions, function (item) {
                          return _c("el-option", {
                            key: item.code,
                            attrs: {
                              label: item.productName,
                              value: item.code,
                            },
                          })
                        }),
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "vxe-table",
            {
              ref: "xTable",
              staticClass: "vxeTable",
              attrs: {
                border: "",
                "row-id": "code",
                loading: _vm.tableLoading,
                data: _vm.tableData,
              },
              on: {
                "checkbox-change": _vm.dialog_checkboxChange,
                "checkbox-all": _vm.dialog_checkAll,
              },
            },
            [
              _c("vxe-table-column", {
                attrs: { type: "checkbox", width: "60" },
              }),
              _vm.isChannel
                ? [
                    _c("vxe-table-column", {
                      attrs: { field: "code", title: "渠道编号" },
                    }),
                    _c("vxe-table-column", {
                      attrs: { field: "name", title: "渠道名称" },
                    }),
                  ]
                : _vm._e(),
              _vm.isCustomer
                ? [
                    _c("vxe-table-column", {
                      attrs: { field: "code", title: "客户编号" },
                    }),
                    _c("vxe-table-column", {
                      attrs: { field: "name", title: "客户名称" },
                    }),
                  ]
                : _vm._e(),
              _vm.isProduct
                ? [
                    _c("vxe-table-column", {
                      attrs: { field: "productCode", title: "商品编号" },
                    }),
                    _c("vxe-table-column", {
                      attrs: { field: "productName", title: "商品名称" },
                    }),
                  ]
                : _vm._e(),
              !_vm.isChannel
                ? _c("vxe-table-column", {
                    attrs: { title: "操作" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _c(
                                "span",
                                {
                                  staticClass: "cursor",
                                  on: {
                                    click: function ($event) {
                                      return _vm.delTable(row, "channel")
                                    },
                                  },
                                },
                                [_vm._v("删除")]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3514391472
                    ),
                  })
                : _vm._e(),
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "dialog-footer" },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: "small",
                    icon: "el-icon-check",
                  },
                  on: { click: _vm.dialog_btnConfirm },
                },
                [_vm._v("提交 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "danger",
                    size: "small",
                    icon: "el-icon-close",
                  },
                  on: { click: _vm.dialog_btnClose },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }