<template>
  <div>
    <!-- 弹框 -->
    <modal  :modalConfig="{type:'Modal',visible:dialog.visible,title:'选择组织',width:'50%'}" @onClose="dialog_btnClose">
      <el-row class="search">
        <el-col :span="8">
          <el-input v-model="params.name" placeholder="请输入组织名称" clearable></el-input>
        </el-col>
        <el-col :span="8" :offset="8">
          <el-button type="primary" @click="handleQuery" icon="el-icon-search">查询</el-button>
          <el-button type="default" @click="handleResetQuery" icon="el-icon-refresh">重置</el-button>
        </el-col>
      </el-row>
      <vxe-table border
                row-id="code"
                ref="xTable"
                class="vxeTable"
                :loading="loading"
                @checkbox-change="dialog_checkboxChange"
                @checkbox-all="dialog_checkAll"
                :data="dialog.treeData"
                :tree-config="{ children: 'children' }"
                :checkbox-config="{checkRowKeys:dialog.checkRowKeys, checkStrictly: true, checkMethod:dialog_isCanCheck}"
                :expand-config="{expandRowKeys:dialog.checkRowKeys}">
          <vxe-table-column type="checkbox" width="60"></vxe-table-column>
          <vxe-table-column tree-node field="name" title="组织名称"></vxe-table-column>
      </vxe-table>
      <div class="dialog-footer">
        <el-button type="primary" @click="dialog_btnConfirm" size="small" icon="el-icon-check">提交 </el-button>
        <el-button type="danger" @click="dialog_btnClose" size="small" icon="el-icon-close">关闭</el-button>
      </div>
    </modal>
  </div>
</template>
<script>
import modal from '../../../../../../../components/modal';
import request from '../../../../../../../utils/request';

export default {
  components: { modal },
  props: {
    orgList: {
      type: Array,
      value: [],
    },
  },
  watch: {
    orgList: {
      deep: true,
      immediate: true,
      handler(val) {
        if (Array.isArray(val)) {
          this.dialog.selection = val;
          this.dialog.checkRowKeys = val.map((v) => v.code);
        }
      },
    },
  },
  data() {
    return {
      dialog: {
        visible: true, // 弹窗状态
        treeData: [], // 列表
        checkRowKeys: [], // 默认选中的
        selection: [], // 选中的
      },
      loading: false,
      params: { // 添加组织增加搜索条件
        name: '',
      },
    };
  },
  created() {
    this.dialog_getList();
  },
  methods: {
    /** @desc 单选框改变事件* @param{records} 所有选中的行，扁平化的列表 */
    dialog_checkboxChange({ records, checked, row }) {
      this.dialog.selection = [...records];
      if (checked) {
        records.map((v) => {
          const item = v;
          const index = this.dialog.checkRowKeys.findIndex((val) => val === item.code);
          if (index === -1) {
            const transver = (value) => {
              if (value.children) {
                this.$refs.xTable.setCheckboxRow(value, true);
                value.children.map((a) => {
                  const pItem = a;
                  this.dialog.selection.push(pItem);
                  this.$refs.xTable.setCheckboxRow(pItem, true);
                  transver(pItem);
                  return a;
                });
              }
            };
            transver(item);
          }
          return item;
        });
      } else {
        this.$refs.xTable.setCheckboxRow(row, false);
      }
    },
    /** @desc 全选事件* @param{records} 所有选中的行，扁平化的列表 */
    dialog_checkAll({ records }) {
      this.dialog.selection = [...records];
    },
    /** @desc 不允许选中的事件 */
    dialog_isCanCheck({ row }) {
      return !row.disabled;
    },
    /** @desc 按钮确认事件 */
    // eslint-disable-next-line consistent-return
    dialog_btnConfirm() {
      if (this.dialog.selection.length === 0) return this.$message.warning('请至少选择一条组织！');
      /** @todo
       * 后端需要的字段是wspCode,wspName
       * parentCode 有parentCode顶级组织（当前及下级组织），没有就是当前组织
       * 直接所有赋值，不需要数据合并和去重，因为此处不存在分页（此时，可坚固，取消已勾选的数据，外部列表数据同时也改变）
      */
      const selection = this.dialog.selection.map((item) => ({
        ...item, wspCode: item.code, wspName: item.name, currentAble: item.parentCode ? 'N' : 'Y', rangeType: '0',
      }));

      // this.dialog.visible = false;
      this.$emit('submitOrg', selection);
    },
    /** @desc 按钮关闭事件 */
    dialog_btnClose() {
      this.dialog.checkRowKeys = [];
      this.dialog.selection = [];
      // this.dialog.visible = false;
      this.$emit('closeOrg');
    },
    /** @desc 获取列表 */
    async dialog_getList() {
      try {
        this.loading = true;
        // const treeResult = await request.post('/mdm/mdmOrgController/findAllChildrenOrgList', { orgCode: JSON.parse(localStorage.getItem('userInfo')).orgCode });
        const treeResult = await request.post('/mdm/baseTreeController/orgTree', this.params, { headers: { functionCode: 'select-org' } });
        this.loading = false;
        const treeData = treeResult ? treeResult.result : [];
        // treeData.map((v) => {
        //   const item = v;
        //   item.rangeType = '0';
        //   delete item.id;
        //   item.name = item.orgName;
        //   item.code = item.orgCode;
        //   return item;
        // });
        // const readyData = [];
        // this.dialog_utilRecureData(treeData, readyData);
        this.dialog.treeData = treeData;
        this.$refs.xTable.setCheckboxRow(this.dialog.selection, true);
      } catch (error) { console.log(error); }
    },
    /** @desc 搜索列表 */
    handleQuery() {
      this.dialog_getList();
    },
    /** @desc 重置查询 */
    handleResetQuery() {
      this.params = {
        name: '',
      };
      this.dialog_getList();
    },
    /** @desc 根据已绑定组织进行重设数据 */
    dialog_utilRecureData(treeData, readyData) {
      const recure = (arr) => {
        arr.forEach((row) => {
          const item = row;
          const find = readyData.find((v) => item.code === v.wspCode);
          item.disabled = !!find;
          if (item.children && Array.isArray(item.children) && item.children.length > 0) {
            recure(item.children);
          }
        });
      };
      recure(treeData);
    },
    // 判断去重
    getReset(list1, list2, key) {
      const list = list2;
      if (list1.length) {
        list1.forEach((v1) => {
          let notRepeat = true;
          const rowData = v1;
          for (const v2 in list2) {
            if (v1[key] === list2[v2][key]) {
              notRepeat = true;
              return false;
            }
          }
          if (notRepeat) {
            list.push(rowData);
          }
          return rowData;
        });
      }
      return list;
    },
  },
};
</script>
<style lang="less" scoped>
  .vxeTable{
    height: 60vh;
  }
  .dialog-footer {
    position: absolute;
    bottom: 0;
    right: 20px;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    z-index: 99;
    background-color: white;
  }
  .search {
    margin-bottom: 10px;
  }
</style>
