<template>
  <div>
    <Modal :modalConfig="modalConfig" @onClose="dialog_btnClose">
      <el-row :gutter="24">
        <el-col :md="8">
          <el-select
            v-model="value"
             v-if="isCustomer"
            class="mb20"
            filterable
            clearable
            @clear="getClear"
            @change="getChange($event, 'code')"
            remote
            reserve-keyword
            placeholder="请输入客户编码或名称"
            :remote-method="(query)=>{remoteMethod(query,'customer')}"
            :loading="loading">
            <el-option
              v-for="item in channelOptions"
              :key="item.code"
              :label="item.name"
              :value="item.code">
            </el-option>
          </el-select>
        </el-col>
        <el-col :md="8">
          <el-select
            v-model="productCode"
            v-if="isProduct"
            class="mb20"
            filterable
            clearable
            remote
            reserve-keyword
            placeholder="请输入商品编码"
            :remote-method="(query)=>{remoteMethod(query,'code')}"
            @clear="getClear"
            @change="getChange($event, 'code')"
            :loading="loading">
            <el-option
              v-for="item in productCodeOptions"
              :key="item.productCode"
              :label="item.productCode"
              :value="item.productCode">
            </el-option>
          </el-select>
        </el-col>
        <el-col :md="8">
          <el-select
            v-model="productName"
            v-if="isProduct"
            class="mb20"
            clearable
            filterable
            remote
            reserve-keyword
            @clear="getClear"
            placeholder="请输入商品名称"
            :remote-method="(query)=>{remoteMethod(query,'name')}"
            @change="getChange($event, 'name')"
            :loading="loading">
            <el-option
              v-for="item in productNameOptions"
              :key="item.code"
              :label="item.productName"
              :value="item.code">
            </el-option>
          </el-select>
        </el-col>
      </el-row>
      <vxe-table border
                row-id="code"
                ref="xTable"
                class="vxeTable"
                :loading="tableLoading"
                @checkbox-change="dialog_checkboxChange"
                @checkbox-all="dialog_checkAll"
                :data="tableData">
          <vxe-table-column type="checkbox" width="60"></vxe-table-column>
          <template v-if="isChannel">
            <vxe-table-column field="code" title="渠道编号"></vxe-table-column>
            <vxe-table-column field="name" title="渠道名称"></vxe-table-column>
          </template>
          <template v-if="isCustomer">
            <vxe-table-column field="code" title="客户编号"></vxe-table-column>
            <vxe-table-column field="name" title="客户名称"></vxe-table-column>
          </template>
          <template v-if="isProduct">
            <vxe-table-column field="productCode" title="商品编号"></vxe-table-column>
            <vxe-table-column field="productName" title="商品名称"></vxe-table-column>
          </template>
          <vxe-table-column title="操作" v-if="!isChannel">
              <template v-slot="{ row }">
                <span class="cursor" @click="delTable(row, 'channel')">删除</span>
              </template>
          </vxe-table-column>
      </vxe-table>

      <div class="dialog-footer">
        <el-button type="primary" @click="dialog_btnConfirm" size="small" icon="el-icon-check">提交 </el-button>
        <el-button type="danger" @click="dialog_btnClose" size="small" icon="el-icon-close">关闭</el-button>
      </div>
    </Modal>
  </div>
</template>
<script>
import Modal from '../../../../../../../components/modal';
import request from '../../../../../../../utils/request';

export default {
  components: {
    Modal,
  },
  props: {
    isChannel: {
      type: Boolean,
      value: false,
    },
    isProduct: {
      type: Boolean,
      value: false,
    },
    isCustomer: {
      type: Boolean,
      value: false,
    },
  },
  data() {
    return {
      modalConfig: {
        title: '选择渠道',
        visible: true,
        type: 'Modal',
        width: '50%',
      },
      productCodeOptions: [],
      productNameOptions: [],
      channelOptions: [],
      loading: false,
      tableLoading: false,
      value: '',
      productName: '',
      productCode: '',
      tableData: [],
      selection: [],
      pageNum: 1,
      pageSize: 15,
    };
  },
  created() {
    if (this.isChannel) {
      this.getChannel();
    } else if (this.isCustomer) {
      this.modalConfig.title = '选择客户';
      this.getCustomer(null);
    } else if (this.isProduct) {
      this.modalConfig.title = '选择产品';
      this.getProduct(null, null);
    }
  },
  methods: {
    // 远程搜索
    remoteMethod(query, type) {
      this.loading = true;
      if (type === 'customer') {
        this.getCustomer(query);
      } else if (type === 'name') {
        this.getProduct(query, null);
      } else if (type === 'code') {
        this.getProduct(null, query);
      }
    },
    // 获取渠道
    getChannel() {
      this.tableLoading = true;
      request.post('/mdm/mdmdictdata/batchDictSelect', ['channel']).then((res) => {
        const data = res.result.channel;
        data.map((v) => {
          const item = v;
          item.name = item.dictValue;
          item.code = item.dictCode;
          item.rangeType = '1';
          delete item.id;
          return item;
        });
        this.tableData = data;
      }).finally(() => {
        this.tableLoading = false;
      });
    },
    // 获取客户
    getCustomer(query, type) {
      const data = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        positionCode: JSON.parse(localStorage.getItem('userInfo')).positionCode,
        terminalCodeOrName: query,
      };
      this.tableLoading = true;
      request.post('/mdm/mdmTerminalController/userTerminalContactPage', data).then((res) => {
        this.totalPage = res.result.count;
        if (res.result.data) {
          res.result.data.map((v) => {
            const item = v;
            item.code = item.terminalCode;
            item.name = item.terminalName;
            item.rangeType = '2';
            delete item.id;
            return item;
          });
        }
        if (type) {
          this.tableData = this.getReset(this.tableData, res.result.data, 'code');
        } else if (query || query === null) {
          this.channelOptions = res.result.data;
        }
      }).finally(() => {
        this.loading = false;
        this.tableLoading = false;
      });
    },
    // 获取商品
    getProduct(name, code, type) {
      const data = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        productCode: code,
        productName: name,
      };
      this.tableLoading = true;
      request.post('/mdm/mdmProductController/commonPageList', data).then((res) => {
        if (res.result.data) {
          res.result.data.map((v) => {
            const item = v;
            item.seriesCode = item.productLevelCode;
            item.seriesName = item.productLevelName;
            item.code = item.productCode;
            item.displayNum = 1;
            delete item.id;
            return item;
          });
        }
        if (type) {
          this.tableData = this.getReset(this.tableData, res.result.data, 'code');
        } else {
          if (name || name === null) {
            this.productCodeOptions = res.result.data;
          }
          if (code || code === null) {
            this.productNameOptions = res.result.data;
          }
        }
      }).finally(() => {
        this.tableLoading = false;
        this.loading = false;
      });
    },
    // 判断去重
    getReset(list1, list2, key) {
      const list = list2;
      if (list1 && list1.length) {
        list1.forEach((v1) => {
          let notRepeat = true;
          const rowData = v1;
          for (const v2 in list2) {
            if (v1[key] === list2[v2][key]) {
              notRepeat = true;
              return false;
            }
          }
          if (notRepeat) {
            list.push(rowData);
          }
          return rowData;
        });
      }
      return list;
    },
    // 清除输入框
    getClear() {
      if (this.isProduct) {
        this.productName = '';
        this.productCode = '';
        this.getProduct(null, null);
      } else {
        this.value = '';
        this.getCustomer(null);
      }
    },
    // 选择商品
    getChange(e, type) {
      if (e) {
        if (this.isProduct) {
          if (type === 'name') {
            this.productCode = '';
          } else {
            this.productName = '';
          }
          this.getProduct(null, e, 'change');
        } else {
          this.getCustomer(e, 'change');
        }
      }
    },
    // 删除
    delTable(row, type) {
      console.log(row, type);
      const index = this.tableData.findIndex((val) => val.code === row.code);
      if (index > -1) {
        this.tableData.splice(index, 1);
      }
    },
    // 单选
    dialog_checkboxChange({ records }) {
      this.selection = records;
    },
    // 全选
    dialog_checkAll({ records }) {
      this.selection = records;
    },
    // 提交弹窗
    dialog_btnConfirm() {
      if (this.selection.length) {
        this.$emit('submitAdd', this.selection);
      } else {
        this.$message.error('请选择数据');
      }
    },
    // 关闭弹窗
    dialog_btnClose() {
      this.$emit('closeAdd');
    },
  },
};
</script>
<style lang="less" scoped>
  .dialog-footer {
    position: absolute;
    bottom: 0;
    right: 20px;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    z-index: 99;
    background-color: white;
  }
  .mb20{
    margin-bottom: 20px;
  }
  .cursor{
    cursor: pointer;
  }
</style>
