var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "modal",
        {
          attrs: {
            modalConfig: {
              type: "Modal",
              visible: _vm.dialog.visible,
              title: "选择组织",
              width: "50%",
            },
          },
          on: { onClose: _vm.dialog_btnClose },
        },
        [
          _c(
            "el-row",
            { staticClass: "search" },
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入组织名称", clearable: "" },
                    model: {
                      value: _vm.params.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.params, "name", $$v)
                      },
                      expression: "params.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8, offset: 8 } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", icon: "el-icon-search" },
                      on: { click: _vm.handleQuery },
                    },
                    [_vm._v("查询")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "default", icon: "el-icon-refresh" },
                      on: { click: _vm.handleResetQuery },
                    },
                    [_vm._v("重置")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "vxe-table",
            {
              ref: "xTable",
              staticClass: "vxeTable",
              attrs: {
                border: "",
                "row-id": "code",
                loading: _vm.loading,
                data: _vm.dialog.treeData,
                "tree-config": { children: "children" },
                "checkbox-config": {
                  checkRowKeys: _vm.dialog.checkRowKeys,
                  checkStrictly: true,
                  checkMethod: _vm.dialog_isCanCheck,
                },
                "expand-config": { expandRowKeys: _vm.dialog.checkRowKeys },
              },
              on: {
                "checkbox-change": _vm.dialog_checkboxChange,
                "checkbox-all": _vm.dialog_checkAll,
              },
            },
            [
              _c("vxe-table-column", {
                attrs: { type: "checkbox", width: "60" },
              }),
              _c("vxe-table-column", {
                attrs: { "tree-node": "", field: "name", title: "组织名称" },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "dialog-footer" },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: "small",
                    icon: "el-icon-check",
                  },
                  on: { click: _vm.dialog_btnConfirm },
                },
                [_vm._v("提交 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "danger",
                    size: "small",
                    icon: "el-icon-close",
                  },
                  on: { click: _vm.dialog_btnClose },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }