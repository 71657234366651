<script>
import Form, { formCreate } from '../../../../../../components/form';
import formAdd from './components';
import request from '../../../../../../utils/request';

formCreate.component('formAdd', formAdd);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [],
      row: null,
    };
  },
  async created() {
    const rule = await this.getFormRule('sfa_activity_task_add');
    const newRule = [];
    rule.forEach((item) => {
      const v = item;
      if (v.field === 'form_add') {
        if (this.formConfig.type === 'view') {
          this.buttons.submit = false;
          newRule.push({
            type: 'formAdd',
            field: 'form_add',
            title: '',
            value: {},
            props: {
              disabled: true,
            },
          });
        } else if (this.formConfig.type === 'add') {
          newRule.push({
            type: 'formAdd',
            field: 'form_add',
            title: '',
            value: {},
            props: {
              isAdd: true,
            },
          });
        } else {
          newRule.push({
            type: 'formAdd',
            field: 'form_add',
            title: '',
            value: {},
          });
        }
      }
    });
    this.rule = newRule;
    this.reload(this.rule);
    if (this.formConfig.type === 'edit' || this.formConfig.type === 'view') {
      request
        .get('/sfa/sfaActivityExecutionController/queryDetailById', {
          id: this.formConfig.id,
        })
        .then((res) => {
          if (res.success) {
            const { result } = res;
            result.activityDate = [result.activityStartTime, result.activityEndTime];
            result.checkList = result.activityRequire.split(',');
            const obj = {
              form_add: result,
            };
            this.setValue(obj);
            this.row = res.result;
          }
        });
    }
  },
  methods: {
    // 提交
    submit() {
      const formData = this.getFormData().form_add;
      console.log('formData', formData);
      if (formData) {
        let url = '/sfa/sfaActivityExecutionController/save';
        let params = { ...formData };

        if (this.formConfig.type === 'edit') {
          url = '/sfa/sfaActivityExecutionController/update';
          params = Object.assign(this.row, formData);
        }
        delete params.orgRangeList;
        delete params.channelRangeList;
        delete params.customerRangeList;

        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });

            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
};
</script>
